export class ApiConstants {
  // Predefined Permissions List
  static PredefinePermissions = [
    'dashboard.read',
    'dashboard.write',
    'dashboard.remove',
    'incidents.read',
    'incidents.write',
    'incidents.remove',
    'students.read',
    'students.write',
    'students.remove',
    'configuration.read',
    'configuration.write',
    'configuration.remove',
    'admin-users.read',
    'admin-users.write',
    'admin-users.remove',
  ];

  // Predefined Permissions List
  static Permissions = {
    // DEFAULT
    DEFAULT: 'default',

    // DASHBOARD
    DASHBOARD_READ: 'dashboard.read',
    DASHBOARD_WRITE: 'dashboard.write',
    DASHBOARD_REMOVE: 'dashboard.remove',

    // INCIDENTS
    INCIDENTS_READ: 'incidents.read',
    INCIDENTS_WRITE: 'incidents.write',
    INCIDENTS_REMOVE: 'incidents.remove',

    // STUDENTS
    STUDENTS_READ: 'students.read',
    STUDENTS_WRITE: 'students.write',
    STUDENTS_REMOVE: 'students.remove',

    // USERS
    ADMIN_USERS_READ: 'admin-users.read',
    ADMIN_USERS_WRITE: 'admin-users.write',
    ADMIN_USERS_REMOVE: 'admin-users.remove',

    // CONFIGURATION
    CONFIGURATION_READ: 'configuration.read',
    CONFIGURATION_WRITE: 'configuration.write',
    CONFIGURATION_REMOVE: 'configuration.remove',
  };

  static Status = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
  };

  // User Type Keys
  static UserType = {
    ADMIN: 'ADMIN',
    MEMBER: 'MEMBER',
  };
}
