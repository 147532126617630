<div class="form-group position-relative my-2">
  <label class="custom-toggle">
    <input
      type="checkbox"
      name="status"
      (change)="onSwitch($event)"
      [checked]="isChecked"
      [disabled]="disabled"
    />
    <span class="slider-toggle round"> </span>
  </label>
</div>
