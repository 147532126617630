/**
 * A generic class representing the response of a network request.
 */
import { BaseModel } from 'src/app/models/BaseModel';

export enum NetworkResponseType {
  SUCCESS = 'NETWORK_RESPONSE_SUCCESS',
  API_ERROR = 'NETWORK_RESPONSE_API_ERROR',
  UNKNOWN_ERROR = 'NETWORK_RESPONSE_UNKNOWN_ERROR',
}

export interface NetworkResponse<T extends BaseModel> {
  code: number;
  responseType: NetworkResponseType;
  response: T;
  error: Error;
}
