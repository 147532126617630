import { BaseModel } from 'src/app/models/BaseModel';
import { ForgotPasswordModel } from 'src/app/models/ForgotPasswordModel';
import { HttpMethod } from '../network/HttpMethod';
import { Injectable } from '@angular/core';
import { LoginModel } from 'src/app/models/LoginModel';
import { NetworkResponse } from '../network/NetworkResponse';
import { NetworkService } from '../network/network.service';
import { ProfileModel } from 'src/app/models/ProfileModel';
import { SignupModel } from 'src/app/models/SignupModel';
import { SignupVerificationModel } from 'src/app/models/SignupVerificationModel';
import { UpdateProfileModel } from 'src/app/models/UpdateProfileModel';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor (private networkService: NetworkService) {
    //Empty Constructor
  }

  /**
   * Request to Login User
   *
   * @param {object} body - Request Body
   * @return Promise with Response
   */
  login (body: object): Promise<NetworkResponse<LoginModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'auth/public/login',
      data: body,
    });
  }

  /**
   * Request to Get User Profile
   *
   * @return Promise with Response
   */
  getProfile (): Promise<NetworkResponse<ProfileModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.GET,
      url: 'auth/admin/profile',
    });
  }

  /**
   * Request to Signup User
   *
   * @param {object} body - Request Body
   * @return Promise with Response
   */
  signup (body: object): Promise<NetworkResponse<SignupModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'auth/public/signup',
      data: body,
    });
  }

  /**
   * Request to Resend Signup Verify Email
   *
   * @param {object} body - Request Body
   * @return Promise with Response
   */
  resendSignupVerifyEmail (body: object): Promise<NetworkResponse<SignupModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'auth/public/resend-signup-email',
      data: body,
    });
  }

  /**
   * Request to Verify Signup Email
   *
   * @param {string} code - Email Verification Code
   * @return Promise with Response
   */
  verifySignupEmail (
    code: string,
  ): Promise<NetworkResponse<SignupVerificationModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: `auth/public/verify-signup/${  code }`,
    });
  }

  /**
   * Request to Send Forgot Password Email
   *
   * @param {object} body - Request Body
   * @return Promise with Response
   */
  forgotPassword (body: object): Promise<NetworkResponse<ForgotPasswordModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'auth/public/forgot-password',
      data: body,
    });
  }

  /**
   * Request to Set New Password
   *
   * @param {object} body - Request Body
   * @return Promise with Response
   */
  resetPassword (body: object): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: 'auth/public/reset-password',
      data: body,
    });
  }

  /**
   * Request to Logout User
   *
   * @return Promise with Response
   */
  logout (): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'auth/logout',
    });
  }

  /**
   * Request to Change Password
   *
   * @param {object} body - Request Body
   * @return Promise with Response
   */
  changePassword (body: object): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: 'auth/change-password',
      data: body,
    });
  }

  /**
   * Request to Update Profile
   *
   * @param {object} body - Request Body
   * @return Promise with Response
   */
  updateProfile (body: object): Promise<NetworkResponse<UpdateProfileModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: 'auth/admin/profile',
      data: body,
    });
  }
}
