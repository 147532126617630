<div
  class="pagination-container d-flex"
  [ngClass]="{
    'justify-content-end': !showTotalEntries,
    'justify-content-between': showTotalEntries
  }"
>
  <div
    class="left-container d-flex align-items-center position-relative"
    *ngIf="showTotalEntries"
  >
    <p class="mb-0">
      Showing {{ currentRangeValues.start }} to {{ currentRangeValues.end }} of
      {{ totalEntries }} entries
    </p>
    <div class="dropdown-container" *ngIf="showPaginationDropdown">
      <ul class="select-box-dropdown">
        <li
          (click)="optionSelectHandler(item, i)"
          [ngClass]="{ disabled: item?.disabled }"
          class="options-box justify-content-between"
          *ngFor="let item of optionList; let i = index"
        >
          <span [ngClass]="{ 'selected-option': item === selectedLimit }">{{
            item
          }}</span>
          <img
            src="assets/icons/ic-check.svg"
            alt=""
            *ngIf="item === selectedLimit"
          />
        </li>
      </ul>
    </div>

    <div
      class="pagination-dropdown d-flex align-items-center justify-content-center"
      (click)="
        $event.preventDefault();
        $event.stopPropagation();
        showPaginationDropdown = !showPaginationDropdown
      "
      [ngClass]="{ 'active-dropdown': showPaginationDropdown }"
    >
      <span>{{ selectedLimit }}</span>
      <img
        class="icon-dropdown"
        [src]="
          showPaginationDropdown
            ? '/assets/icons/ic-chevron-up-light.svg'
            : '/assets/icons/ic-chevron-down-light.svg'
        "
        alt=""
      />
    </div>
  </div>
  <div class="right-container">
    <img
      src="/assets/icons/ic-chevron-double-left-light.svg"
      class="arrow arrow-icon"
      (click)="onPreviousClick(paginationKey.FIRST_PAGE)"
    />
    <img
      src="/assets/icons/ic-chevron-left-light.svg"
      class="arrow arrow-icon"
      (click)="onPreviousClick(paginationKey.PREVIOUS_PAGE)"
    />
    <span class="active-page">{{ activePage }}</span>
    <img
      src="/assets/icons/ic-chevron-right-light.svg"
      class="arrow arrow-icon"
      (click)="onNextClick(paginationKey.NEXT_PAGE)"
    />
    <img
      src="/assets/icons/ic-chevron-double-right-light.svg"
      class="arrow arrow-icon"
      (click)="onNextClick(paginationKey.LAST_PAGE)"
    />
  </div>
</div>
