<div class="input-container position-relative" (click)="$event.stopPropagation()">
  <label class="label lp position-relative mb-1">{{labelText }}</label>
  <div class="input-box d-flex justify-content-between">
    <mat-form-field class="calendar-picker">
      <input matInput [matDatepicker]="myDatepicker" readonly class="calendar-input">
      <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #myDatepicker></mat-datepicker>
    </mat-form-field>

    <img src="/assets/icons/calendar.svg" (click)="myDatepicker.open(); "
    class="cursor-pointer "/>
  </div>
</div>