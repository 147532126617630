<div class="main-container">
  <!-- Logo Container in Collapsed Condition -->
  <section class="m-logo" *ngIf="isSideBarCollapsed">
    <div
      class="logo-container logo d-flex justify-content-center"
      [ngClass]="{ 'ml-0': isSideBarCollapsed }"
      (click)="toggleSidebar()"
    >
      <img src="/assets/icons/app-logo.svg" alt="" />
    </div>
  </section>

  <!-- Logo Container in Non-Collapsed Condition -->
  <section
    class="logo d-flex"
    (click)="toggleSidebar()"
    *ngIf="!isSideBarCollapsed"
  >
    <img
      [src]="'/assets/icons/app-logo-main.png'"
      class="logo-expanded"
      alt=""
    />
  </section>

  <!-- SIDE BAR - Content Container -->
  <div [ngClass]="{ 'slide-middle-list': isMobile }">
    <!-- SIDE BAR - Tabs List -->
    <ng-container *ngFor="let tab of sideBarTabs">
      <!-- SIDE BAR - Tab Item Container -->
      <div
        class="btn-bp d-flex flex-row list-items transition"
        [ngClass]="{ collapsed: isSideBarCollapsed }"
        (click)="navigate(tab)"
      >
        <div
          class="menu-item d-flex align-items-center"
          [ngClass]="{
            active: isActive(tab.key),
            'position-relative': tab.key === 'configurations',
            'justify-content-center': isSideBarCollapsed
          }"
          [ngStyle]="{ 'padding-left': isSideBarCollapsed ? '0' : '8px' }"
        >
          <!-- SIDE BAR - Tab Icon -->
          <img
            src="/assets/icons/{{ tab.image }}"
            alt=""
            srcset=""
            height="20"
            width="20"
            [ngStyle]="{ 'margin-top': isSideBarCollapsed ? '0' : '3px' }"
          />

          <!-- SIDE BAR - Tab Name -->
          <span
            [ngClass]="{ 'collapsed-label': isSideBarCollapsed }"
            class="menu-label"
            >{{ tab.title }}</span
          >
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- START - USER PROFILE MODAL-->
<app-modal
  [showModal]="modals.profileModal"
  [id]="'profileModal'"
  [showCloseButton]="true"
  class="common-modal"
  header="Profile"
  (userInput)="modals.profileModal = false; setInitialActiveTab()"
>
  <div class="form-container">
    <!-- Profile Image Container -->
    <div class="profile-image-container text-center">
      <!-- SIDE BAR - User Profile Image -->
      <img
        *ngIf="!nameInitials"
        [src]="profileData?.picture ?? '/assets/icons/app-logo.svg'"
        class="profile-picture"
        (click)="modals.profileModal = !modals.profileModal"
      />

      <!-- SIDE BAR - Name Initials Text -->
      <div
        *ngIf="nameInitials"
        class="initials-text-container"
        (click)="modals.profileModal = !modals.profileModal"
      >
        <span class="initials-text">{{ nameInitials }}</span>
      </div>
    </div>

    <!-- Heading -->
    <h3 class="text-center heading">{{ profileData?.name }}</h3>

    <!-- Sub-Heading -->
    <h6 class="text-center sub-heading">{{ profileData?.contact?.email }}</h6>

    <!-- Logout Button -->
    <div class="mt-4">
      <app-button
        type="primary"
        height="48px"
        label="Logout"
        borderRadius="12px"
        (clickEvent)="
          modals.logoutModal = !modals.logoutModal;
          modals.profileModal = !modals.profileModal
        "
        class="common-button"
      ></app-button>
    </div>

    <!-- Change Password, Edit Profile Text Button Container -->
    <div class="text-center mt-2">
      <!-- Change Password Button -->
      <div>
        <app-button
          type="text"
          height="36px"
          label="Change Password"
          borderRadius="12px"
          (clickEvent)="onChangePasswordClick()"
        ></app-button>
      </div>

      <!-- Edit Profile Button -->
      <div class="text-button">
        <app-button
          type="text"
          height="36px"
          label="Edit Profile"
          borderRadius="12px"
          (clickEvent)="onEditProfileClick()"
        ></app-button>
      </div>
    </div>
  </div>
</app-modal>
<!-- END - USER PROFILE MODAL-->

<!-- START - LOGOUT MODAL-->
<app-modal
  [showModal]="modals.logoutModal"
  [id]="'logoutModal'"
  [showCloseButton]="true"
  class="common-modal"
  [showHeader]="false"
  (userInput)="modals.logoutModal = false; setInitialActiveTab()"
>
  <div class="form-container">
    <!-- User Profile Image Container -->
    <div class="profile-image-container text-center">
      <!-- SIDE BAR - User Profile Image -->
      <img
        *ngIf="!nameInitials"
        [src]="profileData?.picture ?? '/assets/icons/app-logo.svg'"
        class="profile-picture"
        (click)="modals.profileModal = !modals.profileModal"
      />

      <!-- SIDE BAR - Name Initials Text -->
      <div
        *ngIf="nameInitials"
        class="initials-text-container"
        (click)="modals.profileModal = !modals.profileModal"
      >
        <span class="initials-text">{{ nameInitials }}</span>
      </div>
    </div>

    <!-- Heading Text -->
    <h3 class="text-center heading">Are you sure you want to log out?</h3>

    <div class="col-lg-0 mt-4">
      <app-button
        type="danger"
        label="Yes"
        borderRadius="12px"
        (clickEvent)="requestLogout()"
        class="common-button"
      ></app-button>
    </div>

    <div class="col-lg-0 mt-2">
      <app-button
        type="primary"
        label="No"
        borderRadius="12px"
        (clickEvent)="modals.logoutModal = !modals.logoutModal; setInitialActiveTab()"
        class="common-button"
      ></app-button>
    </div>
  </div>
</app-modal>
<!-- END - LOGOUT MODAL-->

<!-- START - CHANGE PASSWORD MODAL-->
<app-modal
  [showModal]="modals.changePasswordModal"
  [id]="'changePasswordModal'"
  [showCloseButton]="true"
  class="common-modal"
  header="Change Password"
  (userInput)="modals.changePasswordModal = false; setInitialActiveTab()"
>
  <!-- Change Password Form Container -->
  <div
    class="form-container"
    (keyup.enter)="onContinueClick(buttonKey.CHANGE_PASSWORD)"
  >
    <!-- Previous Password Input Field -->
    <div class="input-container">
      <app-input
        id="previousPassword"
        labelText="Previous Password"
        type="password"
        [displayError]="true"
        [required]="showError?.previousPassword"
        (keyupEvent)="
          inputEventHandler($event, inputFieldKey.PREVIOUS_PASSWORD)
        "
        [errorMessage]="'Invalid Previous Password'"
        [value]="inputValue.changePassword.previousPassword"
        [placeholder]="'Enter Previous Password'"
        [showPasswordIcon]="true"
        [mandatory]="true"
      ></app-input>
    </div>

    <!-- New Password Input Field -->
    <div class="input-container">
      <app-input
        id="newPassword"
        labelText="New Password"
        type="password"
        [displayError]="true"
        [required]="showError?.newPassword"
        (keyupEvent)="inputEventHandler($event, inputFieldKey.NEW_PASSWORD)"
        [errorMessage]="'Invalid New Password'"
        [value]="inputValue.changePassword.newPassword"
        [placeholder]="'Enter New Password'"
        [showPasswordIcon]="true"
        [mandatory]="true"
      ></app-input>
    </div>

    <!-- Confirm New Password Input Field -->
    <div class="input-container">
      <app-input
        id="confirmPassword"
        labelText="Confirm Password"
        type="password"
        [displayError]="true"
        [required]="showError?.confirmPassword"
        (keyupEvent)="inputEventHandler($event, inputFieldKey.CONFIRM_PASSWORD)"
        [errorMessage]="'Invalid Confirm Password'"
        [value]="inputValue.changePassword.confirmPassword"
        [placeholder]="'Enter Confirm Password'"
        [showPasswordIcon]="true"
        [mandatory]="true"
      ></app-input>
    </div>

    <!-- Change Button -->
    <div class="mt-4">
      <app-button
        type="primary"
        height="48px"
        label="Change Password"
        borderRadius="12px"
        (clickEvent)="onContinueClick(buttonKey.CHANGE_PASSWORD)"
        class="common-button"
      ></app-button>
    </div>
  </div>
</app-modal>
<!-- END - CHANGE PASSWORD MODAL-->

<!-- START - SUCCESS MODAL-->
<app-modal
  class="success-modal"
  [showModal]="modals.successModal"
  [id]="'successModal'"
  [showSuccessImage]="true"
  [showCloseButton]="true"
  [header]="successMessage"
  (userInput)="modals.successModal = false; setInitialActiveTab()"
>
  <div class="form-container">
    <div class="close-ticket-button">
      <app-button
        type="primary"
        height="48px"
        label="Back"
        borderRadius="12px"
        (clickEvent)="successModalClick(); setInitialActiveTab()"
        class="common-button"
      ></app-button>
    </div>
  </div>
</app-modal>
<!-- END - SUCCESS MODAL-->

<!-- START - EDIT USER PROFILE MODAL-->
<app-modal
  [showModal]="modals.editProfileModal"
  [id]="'editProfileModal'"
  [showCloseButton]="true"
  class="common-modal"
  header="Edit Profile"
  (userInput)="
    modals.editProfileModal = !modals.editProfileModal; setInitialActiveTab()
  "
>
  <div
    class="form-container"
    (keyup.enter)="onContinueClick(buttonKey.EDIT_PROFILE)"
  >
    <!-- Name Input Field -->
    <div class="input-container">
      <app-input
        id="name"
        labelText="Name"
        type="text"
        [displayError]="true"
        [required]="showError?.name"
        (keyupEvent)="inputEventHandler($event, inputFieldKey.NAME)"
        [errorMessage]="'Invalid Name'"
        [value]="profileData?.name"
        [placeholder]="'Enter Name'"
        [mandatory]="true"
      ></app-input>
    </div>

    <!-- Email Input Field -->
    <div class="input-container">
      <app-input
        id="email"
        labelText="Email"
        type="text"
        [displayError]="true"
        [required]="showError?.email"
        (keyupEvent)="inputEventHandler($event, inputFieldKey.EMAIL)"
        [errorMessage]="'Invalid Email'"
        [value]="profileData?.contact?.email"
        [placeholder]="'Enter Email'"
        [mandatory]="true"
        [disabled]="true"
        [showChangeEmail]="false"
      ></app-input>
    </div>

    <!-- Phone Number Input Field -->
    <div class="input-container">
      <app-country-dropdown
        class="country-dropdown"
        labelText="Phone No."
        type="text"
        [displayError]="false"
        (keyupEvent)="inputEventHandler($event, inputFieldKey.NUMBER)"
        [prefix]="profileData?.contact?.phone?.prefix"
        [phoneNumber]="profileData?.contact?.phone?.number"
        [errorMessage]="'Phone No.'"
        (clickEvent)="inputEventHandler($event, inputFieldKey.PREFIX)"
        [showErrorMessage]="showError?.number"
        [mandatory]="true"
        [placeholder]="'Enter Phone Number'"
      ></app-country-dropdown>
    </div>

    <!-- Name Input Field -->
    <div class="input-container">
      <app-input
        id="companyName"
        labelText="Company Name"
        type="text"
        [displayError]="true"
        [required]="showError?.companyName"
        (keyupEvent)="inputEventHandler($event, inputFieldKey.COMPANY_NAME)"
        [errorMessage]="'Invalid Company Name'"
        [value]="profileData?.companyName"
        [placeholder]="'Enter Company Name'"
        [mandatory]="true"
      ></app-input>
    </div>

    <!-- Profile Picture Input Field -->
    <div class="input-container">
      <app-input
        id="profilePicture"
        labelText="Upload Profile Picture"
        type="file"
        [displayError]="false"
        [showErrorMessage]="showError?.picture"
        [placeholder]="'Select Picture'"
        [required]="showError.picture"
        [errorMessage]="'Upload Picture'"
        [uploadedMedia]="profileData?.fileName"
        (inputChange)="inputEventHandler($event, inputFieldKey.PROFILE_PICTURE)"
      ></app-input>
    </div>

    <!-- Save Changes Button -->
    <div class="mt-4">
      <app-button
        type="primary"
        height="48px"
        label="Save Changes"
        borderRadius="12px"
        (clickEvent)="onContinueClick(buttonKey.EDIT_PROFILE)"
        class="common-button"
      ></app-button>
    </div>
  </div>
</app-modal>
<!-- END - EDIT USER PROFILE MODAL-->
