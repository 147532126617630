import { ADConfigurationModel } from 'src/app/models/ADConfigurationModel';
import { BaseModel } from 'src/app/models/BaseModel';
import { CreateUpdateStatusConfigurationModel } from 'src/app/models/CreateUpdateStatusConfigurationModel';
import { EmailConfigurationModel } from 'src/app/models/EmailConfigurationModel';
import { GeneralConfigurationModel } from 'src/app/models/GeneralConfigurationModel';
import { HttpMethod } from '../network/HttpMethod';
import { Injectable } from '@angular/core';
import { MoodleConfigurationModel } from 'src/app/models/MoodleConfigurationModel';
import { NetworkResponse } from '../network/NetworkResponse';
import { NetworkService } from '../network/network.service';
import { NotificationConfigurationModel } from 'src/app/models/NotificationConfigurationModel';
import { StatusConfigurationModel } from 'src/app/models/StatusConfigurationModel';
import { UpdateADConfigurationModel } from 'src/app/models/UpdateADConfigurationModel';
import { UpdateEmailConfigurationModel } from 'src/app/models/UpdateEmailConfigurationModel';
import { UpdateGeneralConfigurationModel } from 'src/app/models/UpdateGeneralConfigurationModel';
import { UpdateMoodleConfigurationModel } from 'src/app/models/UpdateMoodleConfigurationModel';
import { UpdateNotificationConfigurationModel } from 'src/app/models/UpdateNotificationConfigurationModel';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor (private networkService: NetworkService) {
    // Empty Constructor
  }

  /**
   * Request to Get General Configuration
   *
   * @return Promise with Response
   * */
  getGeneralConfiguration (): Promise<
    NetworkResponse<GeneralConfigurationModel>
    > {
    return this.networkService.apiRequest({
      method: HttpMethod.GET,
      url: 'configuration/general',
    });
  }

  /**
   * Request to Update General Configuration
   *
   * @return Promise with Response
   * */
  updateGeneralConfiguration (
    body: object,
  ): Promise<NetworkResponse<UpdateGeneralConfigurationModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: 'configuration/general',
      data: body,
    });
  }

  /**
   * Request to Get Active Directory Configuration
   *
   * @return Promise with Response
   * */
  getActiveDirectoryConfiguration (): Promise<
    NetworkResponse<ADConfigurationModel>
    > {
    return this.networkService.apiRequest({
      method: HttpMethod.GET,
      url: 'configuration/active-directory',
    });
  }

  /**
   * Request to Update Active Directory Configuration
   *
   * @return Promise with Response
   * */
  updateActiveDirectoryConfiguration (
    body: object,
  ): Promise<NetworkResponse<UpdateADConfigurationModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: 'configuration/active-directory',
      data: body,
    });
  }

  /**
   * Request to Get Moodle Configuration
   *
   * @return Promise with Response
   * */
  getMoodleConfiguration (): Promise<NetworkResponse<MoodleConfigurationModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.GET,
      url: 'configuration/moodle',
    });
  }

  /**
   * Request to Update Moodle Configuration
   *
   * @return Promise with Response
   * */
  updateMoodleConfiguration (
    body: object,
  ): Promise<NetworkResponse<UpdateMoodleConfigurationModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: 'configuration/moodle',
      data: body,
    });
  }

  /**
   * Request to Get Email Configuration
   *
   * @return Promise with Response
   * */
  getEmailConfiguration (): Promise<NetworkResponse<EmailConfigurationModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.GET,
      url: 'configuration/ses',
    });
  }

  /**
   * Request to Update Email Configuration
   *
   * @return Promise with Response
   * */
  updateEmailConfiguration (
    body: object,
  ): Promise<NetworkResponse<UpdateEmailConfigurationModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: 'configuration/ses',
      data: body,
    });
  }

  /**
   * Request to Get Notification Configuration
   *
   * @return Promise with Response
   * */
  getNotificationConfiguration (): Promise<
    NetworkResponse<NotificationConfigurationModel>
    > {
    return this.networkService.apiRequest({
      method: HttpMethod.GET,
      url: 'configuration/notification',
    });
  }

  /**
   * Request to Update Notification Configuration
   *
   * @return Promise with Response
   * */
  updateNotificationConfiguration (
    body: object,
  ): Promise<NetworkResponse<UpdateNotificationConfigurationModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: 'configuration/notification',
      data: body,
    });
  }

  /**
   * Request to Get Status List
   *
   * @param {string} search - Search Input
   * @return Promise with Response
   */
  getStatusList (
    search: string = '',
  ): Promise<NetworkResponse<StatusConfigurationModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.GET,
      url: 'configuration/incident-status/list',
      queryParams: {
        searchText: search,
      },
    });
  }

  /**
   * Request to Create New Status
   *
   * @param {object} body - Request Body
   * @return Promise with Response
   */
  createStatus (
    body: object,
  ): Promise<NetworkResponse<CreateUpdateStatusConfigurationModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'configuration/incident-status',
      data: body,
    });
  }

  /**
   * Request to Update The Status
   *
   * @param {object} body - Request Body
   * @return Promise with Response
   */
  updateStatus (
    body: object,
    _id: string,
  ): Promise<NetworkResponse<CreateUpdateStatusConfigurationModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: `configuration/incident-status/${  _id }`,
      data: body,
    });
  }

  /**
   * Request to Delete Status
   *
   * @param {object} body - Request Body
   * @return Promise with Response
   */
  deleteStatus (body: object): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.DELETE,
      url: 'configuration/incident-status',
      data: body,
    });
  }
}
