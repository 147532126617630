export class AppConstants {
  /**
   * Argument Keys
   */
  static Argument = {
    ID: 'id',
  };

  /**
   * Keys for ActionButtons (Edit/Delete/Add etc)
   */
  static ActionButton = {
    ADD_ROLE: 'add_role',
    EDIT_ROLE: 'edit_role',
    DELETE_ROLE: 'delete_role',
    ADD_USER: 'add_user',
    EDIT_USER: 'edit_user',
    DELETE_USER: 'delete_user',
    EXPORT_INCIDENTS: 'export-incidents',
    ADD_INCIDENT: 'add_incident',
    ADD_STATUS: 'add_status',
    IMPORT_STUDENTS: 'import_students',
  };

  /**
   * Keys for Button Types
   */
  static ButtonType = {
    PRIMARY: 'primary',
    ERROR: 'error',
    EDIT: 'edit',
    DELETE: 'delete',
    DISABLED: 'disabled',
    BLOCK: 'block',
  };

  /**
   * Keys for Local Storage Types
   */
  static LocalStorageKeys = {
    AUTH_TOKEN: 'auth-token',
    REMEMBER_ME: 'remember-me',
    STATUS_LIST: 'status-list',
    INCIDENT_ID: 'incident-id',
    LOCATION: 'location',
  };

  /**
   * Keys for Pagination Component
   */
  static PaginationKey = {
    PREVIOUS_PAGE: 'previous-page',
    NEXT_PAGE: 'next-page',
    FIRST_PAGE: 'first-page',
    LAST_PAGE: 'last-page',
  };

  /**
   * Keys for Status Filter
   */
  static StatusFilterKey = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    CLEAR: '',
  };

  /**
   * Keys for Status Keys
   */
  static StatusKey = {
    CLOSED: 'closed',
    OPEN: 'open',
  };

  /**
   * Keys for Document Screens
   */
  static Document = {
    PRIVACY: 'privacy',
    TERMS_AND_CONDITIONS: 'terms_and_conditions',
  };
}

/**
 * Keys for Date Range Picker Event
 */
export enum ChartDateType {
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
}

/**
 * Keys for Sidebar Tabs Navigation Component
 */
export enum TableColumnType {
  input_text = 'inputText',
  toggle = 'toggle',
  checkbox = 'checkbox',
  actions = 'actions',
  select = 'select',
}

/**
 * Keys for Sidebar Tabs Navigation Component
 */
export enum FontType {
  primary = 'primary',
  error = 'error',
}

/**
 * Keys for Sidebar Tabs Navigation Component
 */
export enum PermissionType {
  none = 'none',
  read = 'read',
  full = 'full',
}

/**
 * Keys for Alert Position
 */
export enum AlertPosition {
  TOP_RIGHT = 'top-right',
  TOP_LEFT = 'top-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
  CENTER = 'center',
  CENTER_TOP = 'center-top',
  BOTTOM_CENTER = 'bottom-center',
}

/**
 * Keys for Alert Types
 */
export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}
