import { AlertPosition, AlertType } from 'src/app/constants/AppConstants';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  toggleAlert: Subject<{
    message?: string;
    variant?: AlertType;
    position?: AlertPosition;
    open?: boolean;
  }> = new Subject();

  /**
   * Show Alert Message
   *
   * @param message 'message to display'
   * @param variant 'variant to display'
   * @param position 'position to display'
   */
  showAlert (
    message?: string,
    variant?: AlertType,
    position?: AlertPosition,
  ): void {
    this.toggleAlert.next({
      message: message ?? 'Something went wrong',
      variant: variant ?? AlertType.ERROR,
      position: position ?? AlertPosition.TOP_RIGHT,
      open: true,
    });
  }

  /**
   * Dismiss Alert Message
   *
   * @param message 'message to display'
   * @param variant 'variant to display'
   * @param position 'position to display'
   */
  hideAlert (
    message?: string,
    variant?: AlertType,
    position?: AlertPosition,
  ): void {
    this.toggleAlert.next({
      message,
      variant,
      position,
      open: false,
    });
  }
}
