<div *ngIf="isOpen" class="alert-bar {{ variant }} {{ position }}">
  <div style="position: relative">
    <span class="text">
      {{ message }}
    </span>
    <span (click)="isOpen = false" class="material-icons close-icon">
      close
    </span>
  </div>
</div>
