import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appPasswordMask]',
})
export class PasswordMaskDirective {
  @Output() originalValue = new EventEmitter<string>();
  @Output() changeEvent = new EventEmitter<string>();
  private characters: string[] = [];

  constructor (private el: ElementRef) {
    // Empty Constructor
  }

  @HostListener('input', [ '$event.target.value' ])
  onInput (value: string) {
    // Replace the input value with masked characters
    this.el.nativeElement.value = '*'.repeat(this.characters.length);
    // Emit the original characters
    this.originalValue.emit(this.characters.join(''));
  }

  @HostListener('keydown', [ '$event' ])
  onKeyDown (event: KeyboardEvent) {
    const { selectionStart } = this.el.nativeElement;
    const { selectionEnd } = this.el.nativeElement;
    const selectedText = this.characters.slice(selectionStart, selectionEnd);

    if (event.key === 'Backspace' && selectedText.length <= 1) {
      // Handle Backspace key: remove the last character when a single character is selected
      this.characters.pop();
    } else if (event.key === 'Backspace') {
      // When multiple characters are selected (e.g., select all), remove all characters
      this.characters = [];
    } else if (event.key.length === 1 && event.key !== ' ') {
      // Check if the key is a valid character (not space) and add it to the array
      this.characters.push(event.key);
    }
  }


  @HostListener('change', [ '$event' ])
  onChange (event: any) {
    // Update the input value with the masked characters
    this.el.nativeElement.value = '*'.repeat(this.characters.length);
    // Emit the original characters
    this.changeEvent.emit(this.characters.join(''));
  }
}
