export const showError = {
  // Edit Profile Errors
  name: false,
  email: false,
  prefix: false,
  number: false,
  picture: false,

  // Change Password Errors
  previousPassword: false,
  newPassword: false,
  confirmPassword: false,
};

export const modals = {
  successModal: false,
  profileModal: false,
  logoutModal: false,
  editProfileModal: false,
  changePasswordModal: false,
};

export const inputValue = {
  /** Property to handle new password form values */
  changePassword: {
    previousPassword: '',
    newPassword: '',
    confirmPassword: '',
  },
};

export enum ButtonKey {
  CHANGE_PASSWORD = 'change-password',
  EDIT_PROFILE = 'edit-profile',
}

export enum InputFieldKey {
  COMPANY_NAME='companyName',
  NAME = 'name',
  PREFIX = 'prefix',
  NUMBER = 'number',
  EMAIL = 'email',
  PREVIOUS_PASSWORD = 'previous-password',
  NEW_PASSWORD = 'new-password',
  CONFIRM_PASSWORD = 'confirm-password',
  PROFILE_PICTURE = 'profile-picture'
}
