import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: [ './loader.component.scss' ],
})

/**
 * Represents the Loader Component.
 * This component is responsible for displaying a loader or spinner,
 * indicating that content is being loaded or processed.
 * Implements OnInit and OnDestroy lifecycle hooks for initialization and cleanup.
 */
export class LoaderComponent implements OnInit, OnDestroy {
  // Loader visibility handling
  isLoaderVisible = false;

  @Input() theme = 'light';

  constructor (private sharedService: SharedService) {
    // Empty Constructor
  }

  // Init Method
  ngOnInit (): void {
    this.showLoader();
  }

  /**
   * Hide/Show Loader
   */
  showLoader () {
    this.sharedService.showLoader.subscribe((show: boolean) => {
      this.isLoaderVisible = show;
    });
  }

  // OnDestroy Method
  ngOnDestroy (): void {
    this.isLoaderVisible = false;
  }
}
