/**
 * Types of Content Accept by APIs
 * */
export const Constants = {
  FORM_DATA: 'multipart/form-data',
  JSON: 'application/json',
};

/**
 * Maximum time of Timeout of API
 * */
export const API_TIMEOUT = 1000 * 30;
