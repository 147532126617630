import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { AppConstants } from 'src/app/constants/AppConstants';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: [ './country-dropdown.component.scss' ],
})
export class CountryDropdownComponent implements OnInit, OnChanges {
  /** Property to hold loader visibility */
  @Input() isLoaderVisible = false;

  /** Property to hold theme */
  @Input() theme = 'light';

  @Input() public name = 'name';

  /**
   * Property to decide the height of input bar
   */
  @Input() public height = '48';

  /**
   * Property to handle change event on input
   */
  @Output() inputChange = new EventEmitter<any>();

  /**
   * Property to decide the id of input container
   */
  @Input() public id = '';

  /**
   * Property hold phone prefix
   */
  @Input() prefix = '';

  /**
   * Property hold phone number
   */
  @Input() phoneNumber = '';

  /**
   * Property to display label
   */
  @Input() public showLabel = true;

  /**
   * Property to decide the mandatory input
   */
  @Input() public mandatory = false;

  /**
   * Property to display country dropdown
   */
  showCountryDropdown = false;

  /**
   * Property to hold error Message in label
   */
  errorMessage = 'Invalid Phone No.';

  /**
   * Property to display error Message or not
   */
  @Input() public showErrorMessage = false;

  /**
   * Property to display selected country in dropdown
   */
  selectedCountry = {
    dial_code: '+1',
    flag: 'assets/statics/flags/ad.svg',
    characters: '9',
  };

  /**
   * Property to hold search text in dropdown
   */
  searchText = '';

  /**
   * Property to hold all countries
   */
  countries: any = [];

  /**
   * Property to hold searched countries
   */
  searchedCountries: any = [];

  /**
   * Property to hold the placeholder
   */
  @Input() public placeholder = '';

  /**
   * Property to handle click event on document
   */
  @HostListener('document:click', [ '$event' ]) onDocumentClick (event: any) {
    this.showCountryDropdown = false;
  }

  /**
   * Property to handle click event on country
   */
  @Output() clickEvent = new EventEmitter<any>();

  /**
   * Property to handle keyup event on mobile number
   */
  @Output() keyupEvent = new EventEmitter<any>();

  /**
   * Property to hold label text
   */
  @Input() labelText = 'Name';

  /**
   * Property to bind value to input
   */
  @Input() public value = '';

  @Input() isDropdownClickable = true;

  getMobileValue () {
    if (this.readonly) {
      return this.value;
    }
    return this.phoneNumber;
  }
  @Input() readonly = false;

  constructor (public sharedService: SharedService) {
    this.getSelectedCountry();
  }

  /**
   * Get Selected Country Data
   */
  getSelectedCountry () {
    // Show Loader
    this.isLoaderVisible = true;

    this.sharedService
      .getAllCountries()
      .then((countries) => {
        for (const country of countries) {
          this.countries = [
            ...this.countries,
            {
              ...country,
              flag: `assets/statics/flags/${ country.code.toLowerCase() }.svg`,
            },
          ];
          this.searchedCountries = [
            ...this.countries,
            {
              ...country,
              flag: `assets/statics/flags/${ country.code.toLowerCase() }.svg`,
            },
          ];
        }

        let currentCountry: any = localStorage.getItem(
          AppConstants.LocalStorageKeys.LOCATION,
        );
        if (!currentCountry) {
          // await this.sharedService.getLocationData();
        }
        currentCountry = JSON.parse(currentCountry);
        this.selectedCountry = this.searchedCountries.find((country: any) =>
          (country.code || '').match(
            new RegExp(currentCountry?.countryCode, 'gi'),
          ),
        );
        this.countries = this.countries.filter(
          (country: any) => country !== this.selectedCountry,
        );
        this.countries = [ this.selectedCountry, ...this.countries ];

        // Emit Selected Country Code
        this.clickEvent.emit(this.selectedCountry?.dial_code);

        // Dismiss Loader
        setTimeout(() => {
          this.isLoaderVisible = false;
        }, 1000);
      })
      .catch((error) => {
        console.error('Error getting static text:', error);
      });
  }

  /**
   * Method to handle search event on country dropdown
   */
  public searchEvent () {
    this.countries = this.searchedCountries.filter((country: any) => {
      const nameMatch = (country.name || '')
        .toLowerCase()
        .includes(this.searchText.toLowerCase());
      const codeMatch =
        (country.dial_code || '').includes(this.searchText) ||
        (country.dial_code || '').includes(`+${ this.searchText }`);
      return nameMatch || codeMatch;
    });
  }

  /**
   * Method to format the phone number
   * @param event keyup input value
   */
  phoneNumberValue (event: any) {
    this.phoneNumber = event.target.value;
  }

  /**
   * On Country item Select
   * @param country selected country
   */
  onCountrySelect (country: any) {
    this.selectedCountry = country;
    this.countries = this.countries?.filter((c: any) => c !== country);
    this.countries.sort((a: any, b: any) => a?.name.localeCompare(b?.name));
    this.countries = [ country, ...this.countries ];
    this.showCountryDropdown = !this.showCountryDropdown;
    this.clickEvent.emit(this.selectedCountry?.dial_code);
  }

  /**
   * Emit Input Keyup Event
   */
  inputKeyEventHandler (event: any) {
    this.keyupEvent.emit(event);
  }

  /**
   * On Init Method
   */
  ngOnInit (): void {
    // Set Default Prefix
    if (this.prefix) {
      this.selectedCountry = this.countries?.find(
        (country: any) => country?.dial_code === this.prefix,
      );
    }
  }

  /**
   * On Changes Method
   */
  ngOnChanges (): void {
    // Set Default Prefix
    if (this.prefix) {
      this.selectedCountry = this.countries?.find(
        (country: any) => country?.dial_code === this.prefix,
      );
    }
  }

  onPaste (event: ClipboardEvent): void {
    // Prevent paste action
    event.preventDefault();
  }
}
