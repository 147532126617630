import { AppConstants } from 'src/app/constants/AppConstants';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: [ './redirect.component.scss' ],
})
export class RedirectComponent {

  constructor (private router: Router) {

    const token = window.location.pathname.split('/')[2];
    sessionStorage.setItem(AppConstants.LocalStorageKeys.AUTH_TOKEN, token);
    this.router.navigate([ '/' ]);
  }
}
