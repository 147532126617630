import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { MediaService } from 'src/app/services/media/media.service';
import { NetworkResponseType } from 'src/app/services/network/NetworkResponse';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: [ './input.component.scss' ],
})
export class InputComponent implements OnInit {
  /** Property to hold calendar min date */
  @Input() minDate: Date;

  /** Property to hold calendar max date */
  @Input() maxDate: Date;

  /** Property to hold file type */
  @Input() fileType = '.jpg, .jpeg, .png';

  public hide = true;
  /**
   * Property to decide the theme
   */
  @Input() public theme = 'light';

  /**
   * Property to decide the type of input
   */
  @Input() public type = 'name';

  @Input() public disabled = false;

  /**
   * Property to decide the required input
   */
  @Input() public required = true;

  /**
   * Property to decide the mandatory input
   */
  @Input() public mandatory = false;

  @Input() public name = 'name';

  /**
   * Property to decide the height of input bar
   */
  @Input() public height = '48';

  /**
   * Property to handle change event on input
   */
  @Output() inputChange = new EventEmitter<any>();

  /**
   * Property to handle key up event on input
   */
  @Output() keyupEvent = new EventEmitter<any>();

  /**
   * Property to handle change event on input
   */
  @Output() isError = new EventEmitter<any>();

  /**
   * Property to decide the id of input container
   */
  @Input() public id = '';

  /**
   * Property to hold the placeholder
   */
  @Input() public placeholder = '';

  /**
   * Property to display the error message if the input is wrong
   */
  @Input() public errorMessage = '';

  /**
   * Property to check if file is uploaded or not
   */
  public isUploaded = false;

  /** Property to hold picture file name */
  @Input() uploadedMedia = '';

  public isFileUploading = false;

  /**
   * Property to decide whether to display the error message or not
   */
  public showErrorMessage = false;

  /**
   * Property to decide whether to display the error message or not
   */
  @Input() displayError = false;

  /**
   * Property to decide whether to display the text area or not
   */
  public showTextArea = false;

  /**
   * Property to show check icon
   */
  public isInputCorrect = false;

  /**
   * Property to show image in input field
   */
  public showImage = true;

  /**
   * Property to display label
   */
  @Input() public showLabel = true;

  /**
   * Property to display Change Email
   */
  @Input() public showChangeEmail = false;

  /**
   * Property to display label
   */
  @Input() public showErrorIcons = true;

  /**
   * Property to hold label text
   */
  @Input() labelText = 'Name';

  /**
   * Property to hold label text
   */
  @Input() showInputField = true;

  /**
   * Property to bind value to input
   */
  @Input() public value = '';

  /**
   * Property to show counter in input icon
   */
  @Input() public showCounter = false;

  /**
   * Property to hold counter max value
   */
  @Input() public maxLength = 4096;

  /**
   * Property to show password visibility icon in input
   */
  @Input() public showPasswordIcon = false;

  /**
   * Property to hold input value length
   */
  inputCurrentLength = 0;
  icon = 'on';

  @Input() minLength = 0;

  /**
   * Property to show tooltip
   */
  @Input() showTooltip = false;

  /**
   * Property to configure tooltip text
   */
  @Input() tooltipText = '';

  /**
   * Property to configure tooltip text
   */
  @Input() resetOnChange = false;

  /**
   * Property to make input readonly
   */
  @Input() readonly = false;

  /**
   * Property to take info message
   */
  @Input() infoMessage = '';

  @ViewChild('textAreaRef', { static: false })
    textAreaRef!: ElementRef<HTMLTextAreaElement>;

  // START: DATE PICKER
  @ViewChild('datePicker') datePicker: MatDatepicker<any>;

  /**
   * Property to handle date change event
   */
  @Output() dateChangeEvent = new EventEmitter<any>();

  /** Property to hold current date */
  currentDateTime = new Date();

  /** Property to hold default date */
  @Input() defaultDate = this.currentDateTime;
  // END: DATE PICKER

  constructor (
    private cdr: ChangeDetectorRef,
    public sharedService: SharedService,
    private alertService: AlertService,
    private mediaService: MediaService,
  ) {
    // Set min date to 1 year ago from today
    this.minDate = new Date();
    this.minDate.setFullYear(this.minDate.getFullYear() - 1);

    // Set max date to today
    this.maxDate = new Date();
  }

  ngOnInit (): void {
    if (document.body.classList.contains('body-dark')) {
      this.theme = 'dark';
    } else {
      this.theme = 'light';
    }
  }

  /**
   * Property to emit onValue change event
   */
  public inputValueHandler (event: any, type?: string) {
    if (
      this.validateInput((event?.target?.value || event || '').trim(), type)
    ) {
      this.showErrorMessage = true;
      this.isError.emit(true);
    } else {
      this.showErrorMessage = false;
      this.isInputCorrect = true;
      this.isError.emit(false);
      this.inputChange.emit(event);

      if (this.resetOnChange) {
        setTimeout(() => {
          this.value = '';
        }, 200);
      }
    }
  }

  removeFile () {
    if (this.uploadedMedia || this.isUploaded) {
      this.isUploaded = false;
      this.uploadedMedia = '';
      this.inputChange.emit(this.uploadedMedia);
    }
  }

  /**
   * Property to emit on Change Email Click
   */
  changeEmail () {
    this.inputChange.emit('changeEmail');
  }

  /**
   * Property to emit onValue keyup event
   */
  public inputKeyEventHandler (event: any) {
    if (this.type === 'number') {
      this.validateInputNumber(event);
    }
    this.inputCurrentLength = event.target.value.length;
    this.keyupEvent.emit(event);
  }

  private validateInput (inputValue: string, type?: string) {
    // Change Detector Ref
    this.cdr.detectChanges();

    let isError = false;
    switch (this.type || type) {
    case 'phone':
      isError = true;
      break;
    case 'url':
      if (!inputValue.match(new RegExp(this.sharedService.urlRegex))) {
        isError = true;
        this.errorMessage = 'Invalid URL';
      } else {
        isError = false;
        this.isInputCorrect = true;
      }
      break;
    case 'name':
      if (inputValue.length < 2 || inputValue.length > 50) {
        isError = true;
        this.errorMessage = 'Invalid Name';
      } else {
        isError = false;
        this.isInputCorrect = true;
      }
      break;
    default:
      break;
    }
    return isError;
  }

  public showPassword () {
    if (this.type === 'password') {
      this.type = 'text';
      this.icon = 'off';
    } else {
      this.type = 'password';
      this.icon = 'on';
    }
  }

  validateInputNumber (event: Event) {
    return this.sharedService.checkInput(event, 'number', 2);
  }

  /**
   * On Date Input Click
   */
  onCalendarClick () {
    // Open Date Picker
    this.datePicker.open();
  }

  /**
   * Handle Date Change Event
   * @param event Selected Event
   */
  onDateChange (event: any) {
    // Emit Date Select Event
    this.dateChangeEvent.emit(event);
  }

  // API Call - Upload Media
  async uploadMedia (event: any, type?: string | null | undefined) {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.files && !(inputElement.files.length > 0)) {
      return this.alertService.showAlert('Please select an image');
    }

    // Show Loading
    this.isFileUploading = true;

    // Prepare Form Data
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('type', type ?? '*/*');
    formData.append('file', file, file.name);

    // Get File Size
    const fileSize = file.size;
    const fileSizeReadable = Math.round(fileSize / 1024);

    // Check File Size
    if (fileSizeReadable > 20480) {
      this.alertService.showAlert(
        'File too Big, please select a file less than 20MB',
      );
      return;
    }

    // Get API Response
    const apiResponse = await this.mediaService.upload(formData);

    // Consume Response
    switch (apiResponse.responseType) {
    case NetworkResponseType.SUCCESS:
      if (apiResponse?.response?.status) {
        // Check Message User Data
        if (apiResponse?.response?.data !== null) {
          // Set Hide Error Message
          this.showErrorMessage = false;
          this.required = false;

          // Set Uploaded Media Name
          this.uploadedMedia = this.sharedService.extractFileNameFromUrl(
            apiResponse?.response?.data,
          );

          // Set Is Uploaded
          this.isUploaded = true;

          // Emit Response URL Event
          this.inputChange.emit(apiResponse?.response?.data);
        }

        // Show Loading
        this.isFileUploading = false;
        return;
      }

      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;

    case NetworkResponseType.API_ERROR:
      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;

    case NetworkResponseType.UNKNOWN_ERROR:
      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;
    }

    // Show Loading
    this.isFileUploading = false;
  }
}
