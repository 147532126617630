import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: [ './button.component.scss' ],
})
export class ButtonComponent {
  @Input() label = 'Send';

  @Input() type: 'primary' | 'secondary' | 'danger'  | 'text' | 'edit' | 'delete' | 'block' | 'success' | 'warning' = 'primary';

  @Input() height = '48px';

  @Input() width = '100px';

  @Input() disabled = false;

  @Input() borderRadius = '0.50rem';

  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  onClickButton () {
    this.clickEvent.emit('Event');
  }

}
