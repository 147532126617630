import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: [ './dropdown-input.component.scss' ],
})
export class DropdownInputComponent {
  /** Property to hold label text */
  @Input() labelText = 'Select';

  /** Property to hold value to show/hide label text */
  @Input() showLabel = true;

  /** Property to decide the mandatory input */
  @Input() public mandatory = false;

  /** Property to hold the placeholder */
  @Input() public placeholder = 'Select Option';

  /** Property to hold error Message in label */
  @Input() errorMessage = 'Please Select Option';

  /** Property to display error Message or not */
  @Input() public showErrorMessage = false;

  /** Property to hold the reversed dropdown */
  @Input() isReversed = false;

  /** Property to hold the dropdown visibility */
  @Input() showDropDown = false;

  /** Property to hold option list in {_id: string, title: string,isDisabled: boolean } object format */
  @Input() optionList: any[] = [];

  /** Property to hold selected option */
  @Input() selectedOption: any = {};

  /** Property to hold label text */
  @Input() value = '';

  /** Property to decide the required input */
  @Input() public required = false;

  /** Property to emit selected option to parent component */
  @Output() clickEvent = new EventEmitter<any>();

  @Output() searchEvent = new EventEmitter<any>();

  @Input() enableSearch = false;

  @Input() id = 'dropdown';

  @Input() theme = 'light';

  /** Property to make input readonly */
  @Input() readonly = false;

  /** Primary Constructor */
  constructor () {
    // Empty
  }

  /**
   * On Int Method
   */
  ngOnInt () {
    if (document.body.classList.contains('body-dark')) {
      this.theme = 'dark';
    } else {
      this.theme = 'light';
    }
  }

  /**
   * On Option Click
   */
  onOptionClick (event: any) {
    // If Item Item is Disabled Than Return
    if (event?.isDisabled) {
      return;
    }

    // Hide Dropdown
    this.showDropDown = false;

    // Set Selected Option Detail
    this.selectedOption = {
      _id: event._id,
      title: event.title,
    };

    // Emit Selected Option
    this.clickEvent.emit(event);

    // Emit Search Event
    this.searchEvent.emit('');

    this.errorMessage = '';
    this.required = false;
    this.showErrorMessage = false;
  }

  searchEventHandler (event: any) {
    this.searchEvent.emit(event);
  }

  toggleDropdown (event: any) {
    if (!this.readonly) {
      event.stopPropagation();

      // Show/Dismiss Dropdown
      this.showDropDown = !this.showDropDown;
    }
  }
}
