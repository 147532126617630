<!-- Root Container -->
<div
  #modal
  class="modal fade"
  [id]="id"
  tabindex="-1"
  aria-hidden="true"
  (click)="action(false)"
>
  <!-- Modal Container -->
  <div
    class="modal-dialog"
    role="document"
    style="width: 475px; border-radius: 10px"
  >
    <!-- Modal Content Container -->
    <div class="modal-content" (click)="closeDropdowns($event)">
      <!-- Success Icon Container -->
      <div class="success-icon-container text-center" *ngIf="showSuccessImage">
        <img src="assets/icons/ic-success.svg" alt="" />
      </div>

      <!-- Header, Close Button Container -->
      <div class="modal-header p-0">
        <!-- Header Text -->
        <span class="header-text" *ngIf="showHeader">{{ header }}</span>

        <!-- Close Button Container -->
        <div
          *ngIf="showCloseButton"
          type="button"
          class="close cursor-pointor"
          data-dismiss="modal"
          aria-label="Close"
          (click)="action(false)"
        >
          <!-- Cross Icon -->
          <span class="cross-icon">
            <img src="/assets/icons/ic-cross-{{ theme }}.svg" />
          </span>
        </div>
      </div>

      <!-- Modal Body Container -->
      <div class="modal-body p-0">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
