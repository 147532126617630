/**
 * Keys for Navigation Component
 */
export enum NavigationScreen {
  /**
   * Tab Screens
   * Remember: Screen Name should be same as Tab Name
   * */
  ADMIN_DASHBOARD = '/admin/dashboard',
  ADMIN_INCIDENTS = '/admin/incidents',
  ADMIN_STUDENTS = '/admin/students',
  ADMIN_USERS = '/admin/admin-users',
  ADMIN_CONFIGURATION = '/admin/configuration',

  // Student Tab Screens
  // STUDENT_LIST = "student-list",
  ADMIN_STUDENT_LISTS = 'admin/students/student-list',
  DETAILS = 'student-details',
  STUDENT_DETAILS_ROUTE = 'admin/students/student-details/',
  INCIDENT_DETAILS = 'incident-details',
  INCIDENT_DETAILS_ROUTE = 'admin/incidents/incident-details/',

  LOGIN = 'login',
  SIGNUP = 'signup',
  SIGNUP_EMAIL_SENT = 'signup/email-sent',
  SIGNUP_VERIFICATION = 'signup/verification',
  FORGOT_PASSWORD = 'forgot-password',
  FORGOT_PASSWORD_EMAIL_SENT = 'forgot-password/email-sent',
  SET_NEW_PASSWORD = 'set-new-password',
  NEW_PASSWORD_CREATED = 'new-password-created'
}

/**
 * Keys for Sub Navigation Screen
 */
export enum SubNavigationScreen {
  OPEN_INCIDENTS = 'open-incidents',
  PAST_INCIDENTS = 'past-incidents',
  STUDENT_LIST = 'student-list',
  COMMENTS = 'comments',
  ACTIVITY = 'activity',
}

/**
 * Keys for Authentication Navigation Component
 */
export enum AuthenticationScreen {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  SIGNUP_VERIFY_MAIL_SENT = 'SIGNUP_VERIFY_MAIL_SENT',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_MAIL_SENT = 'FORGOT_PASSWORD_MAIL_SENT',
  SET_NEW_PASSWORD = 'SET_NEW_PASSWORD',
  NEW_PASSWORD_CREATED = 'NEW_PASSWORD_CREATED',
  PRIVACY = 'PRIVACY',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
}

/**
 * Keys for Sidebar Tabs Navigation Component
 */
export enum SideBarTabs {
  DASHBOARD = 'dashboard',
  INCIDENTS = 'incidents',
  STUDENTS = 'students',
  ADMIN_USERS = 'admin-users',
  CONFIGURATION = 'configuration',
  PROFILE = 'profile',
  LOGOUT = 'logout',
}
