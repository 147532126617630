import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [ './checkbox.component.scss' ],
})
export class CheckboxComponent {
  @Input() name = 'checkbox';
  @Input() id = 'checkbox';
  @Input() label = 'checkbox';
  @Input() checked = false;

  @Output() checkedChange = new EventEmitter<boolean>();

  onCheckboxChange () {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
