<div class="checkbox-container d-flex align-items-center">
  <input
    type="checkbox"
    [name]="name"
    [id]="id"
    (change)="onCheckboxChange()"
    class="checkbox"
    (click)="$event.stopPropagation()"
    [checked]="checked"
  />
  <label for="{{ id }}" class="checkbox-label" (Click)="onCheckboxChange()">{{
    label
  }}</label>
</div>
