import * as Plotly from 'plotly.js-dist-min';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: [ './line-chart.component.scss' ],
})
export class LineChartComponent implements AfterViewInit, OnChanges {
  /** Property to hold the data to show on line chart */
  // Below data is dummy
  @Input() data = [];

  /** Property to hold the id of the chart */
  @Input() id = 'lineChart';

  /**
   * Primary Constructor
   */
  constructor () {
    // Empty constructor
  }

  /**
   * After View Init Method
   */
  ngAfterViewInit (): void {
    this.renderLineGraph();
  }

  /**
   * On Changes Method
   */
  ngOnChanges () {
    this.renderLineGraph();
  }

  /**
   * Render Line Graph
   */
  renderLineGraph () {
    const modifiedData = this.data.map((element) => {
      return {
        x: [ ...element.x ],
        y: [ ...element.y ],
        mode: 'lines+markers',
        name: element.name,
        marker: {
          color: element.color,
          size: 8,
        },
        line: {
          color: element.color,
          width: 1,
          shape: 'spline',
        },
        hovertemplate: '<b>%{y}</b><extra></extra>',
      };
    });

    const layout = {
      xaxis: {
        showline: true,
        showgrid: false,
        showticklabels: true,
        linewidth: 1,
        autotick: false,
        tickfont: {
          family: 'NotoSans-Regular',
          size: 12,
          color: 'rgb(82, 82, 82)',
        },
        linecolor: '#E5E5E5',
        tickangle: -45,
        fixedrange: true,
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
        showline: true,
        showticklabels: true,
        rangemode: 'normal',
        linecolor: '#E5E5E5',
        tickfont: {
          family: 'NotoSans-Regular',
          size: 12,
          color: 'rgb(82, 82, 82)',
        },
        fixedrange: true,
      },
      // Change legend position
      legend: {
        x: 0.5,
        y: 1.2,
        font: {
          family: 'NotoSans-Regular',
          size: 12,
          color: '#000000',
        },
        orientation: 'h',
        xanchor: 'center',
        yanchor: 'bottom',
        itemdoubleclick: false,
      },
      margin: {
        pad: [ 30, 40 ],
        l: 60,
        r: 30,
        t: 30,
        b: 120,
      },
      paper_bgcolor: 'transparent',
      plot_bgcolor: 'transparent',
      autosize: true,
      title: null,
      dragmode: false,
    } as any;

    Plotly.newPlot(this.id, modifiedData, layout, {
      displayModeBar: false,
      scrollZoom: false,
      doubleClick: false,
      responsive: true,
    });
  }
}
