import { AlertPosition, AlertType } from 'src/app/constants/AppConstants';
import { Component, OnDestroy } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: [ './alert-bar.component.scss' ],
})
export class AlertBarComponent implements OnDestroy {
  /** Alert Message */
  message: any = 'Something went wrong';

  /** Alert Type */
  variant: any = AlertType.ERROR;

  /** Alert Position */
  position: any = AlertPosition.TOP_RIGHT;

  /** Alert Timeout */
  timeout: any;

  /** Alert Status */
  public isOpen = false;

  /** Alert Subscription */
  private subscription$: any = null;

  /**
   * Primary Constructor
   */
  constructor (private alertService: AlertService) {
    // Subscribe Alert Toggle From Alert Service
    this.subscription$ = this.alertService.toggleAlert.subscribe((value) => {
      // Assign values from alert service
      this.message = value.message;
      this.variant = value.variant;
      this.position = value.position;
      this.isOpen = value.open;
      clearTimeout(this.timeout);

      // Dismiss Alert
      if (value.open) {
        this.timeout = setTimeout(() => {
          this.isOpen = false;
        }, 3000);
      }
    });
  }

  /**
   * OnDestroy Method
   */
  ngOnDestroy (): void {
    this.subscription$.unsubscribe();
  }
}
