import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AppConstants } from 'src/app/constants/AppConstants';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: [ './pagination.component.scss' ],
})
export class PaginationComponent implements OnInit {
  /** Active page count */
  @Input() activePage = 1;

  /** Total entries count */
  @Input() totalEntries = 0;

  /** Selected page limit count */
  @Input() selectedLimit = '20';

  /** Total entries count */
  public showPaginationDropdown = false;

  /** Current range values */
  public currentRangeValues = {
    start: 1,
    end: 20,
  };

  /** Page entries limit count list */
  public optionList = [ '10', '20', '30', '40', '50' ];

  /** Show/Hide total entries */
  @Input() showTotalEntries = true;

  /** Property to emit selected option value to parent comp */
  @Output() clickEvent = new EventEmitter<any>();

  /** Property to emit current page number */
  @Output() currentPage = new EventEmitter<any>();

  @HostListener('document:click', [ '$event' ]) onDocumentClick (event: any) {
    this.showPaginationDropdown = false;
  }

  /** Pagination Keys */
  paginationKey = AppConstants.PaginationKey;

  constructor (private sharedService: SharedService) {
    //Empty Constructor
  }

  ngOnInit () {
    this.sharedService.refreshRoute.subscribe((route: string) => {
      if (route) {
        // Set active page number
        this.activePage = 1;

        // Hide page limit dropdown
        this.showPaginationDropdown = false;

        // Set page limit start value
        this.currentRangeValues.start = 1;

        // Set selected page limit
        this.selectedLimit = '20';

        // Set page limit end value
        this.currentRangeValues.end = Number(this.selectedLimit);
      }
    });
  }

  /**
   *
   * @param item Selected item value
   * @param index Selected item index
   */
  optionSelectHandler (item: any, index: number) {
    if (item?.disabled) {
      return;
    }

    // Hide page limit dropdown
    this.showPaginationDropdown = false;

    // Set page limit start value
    this.currentRangeValues.start = 1;

    // Set page limit end value
    this.currentRangeValues.end = Number(item);

    // Set selected page limit
    this.selectedLimit = item;

    // Set active page number
    this.activePage = 1;

    // Emit the selected limit of a page
    this.clickEvent.emit({ item, index });

    // Emit the selected page number
    this.currentPage.emit({ page: this.activePage, event: 'changeOption' });
  }

  /**
   * Previous Page Icon Click
   * @param pageKey Page Key (previous-page/first-page)
   */
  onPreviousClick (pageKey: string) {
    // Check if active page is greater than 1 then user can click on previous page icons
    if (this.activePage > 1) {
      switch (pageKey) {
      case this.paginationKey.PREVIOUS_PAGE:
        this.activePage--;
        break;
      case this.paginationKey.FIRST_PAGE:
        this.activePage = 1;
        break;
      default:
        break;
      }
      this.currentRangeValues.start = Math.max(
        (this.activePage - 1) * Number(this.selectedLimit) + 1,
        1,
      );
      this.currentRangeValues.end = Math.min(
        this.activePage * Number(this.selectedLimit),
        this.totalEntries,
      );

      this.currentPage.emit({ page: this.activePage, event: 'previous' });
    }
  }

  /**
   * Next Page Icon Click
   * @param pageKey Page Key (next-page/last-page)
   */
  onNextClick (pageKey: string) {
    // Calculate the maximum number of pages based on the selected page size
    const maxPage = Math.ceil(this.totalEntries / Number(this.selectedLimit));

    // Check if active page is less than maximum page then user can click on next page icons
    if (this.activePage < maxPage) {
      switch (pageKey) {
      case this.paginationKey.NEXT_PAGE:
        this.activePage++;
        break;
      case this.paginationKey.LAST_PAGE:
        this.activePage = maxPage;
        break;
      default:
        break;
      }

      // Calculate the new start value
      this.currentRangeValues.start =
        (this.activePage - 1) * Number(this.selectedLimit) + 1;

      // Calculate the new end value, ensuring it does not exceed the total number of entries
      this.currentRangeValues.end = Math.min(
        this.activePage * Number(this.selectedLimit),
        this.totalEntries,
      );

      this.currentPage.emit({ page: this.activePage, event: 'next' });
    }
  }
}
