<div class="input-container">
  <!-- Label, Change Email Row Container -->
  <div class="row-container">
    <!-- Label Text -->
    <label
      class="label lp position-relative mb-1"
      *ngIf="showLabel"
      [ngClass]="{ 'required-text': showErrorMessage || required }"
      >{{
        (showErrorMessage && displayError) || required
          ? errorMessage
          : labelText
      }}<span class="mandatory" *ngIf="showLabel && mandatory"> *</span>
      </label
    >

    <!-- Change Email Text -->
    <span
      *ngIf="showChangeEmail"
      class="change-email lp position-relative mb-1"
      (click)="changeEmail()"
      >Change Email</span
    >
  </div>

  <div class="position-relative">
    <!-- Primary Input Field -->
    <input
      *ngIf="
        type !== 'textarea' &&
        showInputField &&
        type !== 'file' &&
        type !== 'date'
      "
      [ngClass]="{
        'search-field': type === 'search',
        required: showErrorMessage || required
      }"
      class="input-box"
      [ngClass]="{
        'disabled-input-box': disabled === true,
        'padding-right': showPasswordIcon === true
      }"
      [id]="id"
      name="{{ name }}"
      (keydown)="sharedService.checkInput($event, type, maxLength)"
      (change)="inputValueHandler($event)"
      (keyup)="inputKeyEventHandler($event)"
      [type]="type"
      [disabled]="disabled"
      [value]="value ?? ''"
      [placeholder]="placeholder"
      [style.height]="height + 'px'"
      [required]="required"
      [readonly]="readonly"
    />

    <!-- Date Type Input Field -->
    <input
      *ngIf="type === 'date'"
      [ngClass]="{ required: showErrorMessage || required }"
      class="input-box cursor-pointor"
      [ngClass]="{ 'disabled-input-box': disabled === true }"
      [id]="id"
      name="{{ 'date' }}"
      (change)="inputValueHandler($event)"
      (keyup)="inputKeyEventHandler($event)"
      [type]="'text'"
      [disabled]="disabled"
      [value]="value"
      [placeholder]="placeholder"
      [style.height]="height + 'px'"
      [required]="required"
      [readonly]="true"
    />

    <!-- Date Icon Container -->
    <span
      *ngIf="type === 'date'"
      class="password-icon-container position-absolute"
      (click)="onCalendarClick()"
    >
      <!-- Date Icon -->
      <img
        src="/assets/icons/ic-calendar-light.svg"
        class="password-icon cursor-pointor"
      />
    </span>

    <!-- Date Picker Calendar -->
    <mat-form-field class="calendar-picker" *ngIf="type === 'date'">
      <input
        matInput
        [matDatepicker]="datePicker"
        placeholder="Select date"
        readonly
        (dateChange)="onDateChange($event)"
        [min]="minDate"
        [max]="maxDate"
        [value]="defaultDate"
      />

      <mat-datepicker-toggle
        matSuffix
        [for]="datePicker"
        class="toggle"
      ></mat-datepicker-toggle>

      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>

    <!-- File Type Input Field Container -->
    <div
      class="position-relative file-container"
      *ngIf="showInputField && type === 'file'"
      [ngClass]="{ 'error-file-container': showErrorMessage || required }"
    >
      <!-- Media File Name Text  -->
      <span *ngIf="uploadedMedia" class="uploaded-media-name">{{
        uploadedMedia
      }}</span>

      <!-- Media File Placeholder Text -->
      <span *ngIf="!uploadedMedia" class="placeholder-text">{{
        placeholder
      }}</span>

      <!-- File Type Input Field -->
      <input
        class="input-file"
        type="file"
        id="file"
        name="file"
        (change)="uploadMedia($event, 'image')"
        (click)="$event.stopPropagation()"
        [ngClass]="{ 'd-none': uploadedMedia }"
        accept="{{ fileType }}"
        [id]="id"
      />

      <!-- File Loader -->
      <div *ngIf="isFileUploading" class="loader-container position-absolute">
        <img src="assets/icons/ic-button-loader.svg" alt="loader" />
      </div>

      <!-- Upload/Remove Button -->
      <span
        *ngIf="!isFileUploading"
        class="position-absolute upload-button"
        [ngClass]="{ 'remove-button': uploadedMedia }"
        (click)="removeFile()"
        >{{ uploadedMedia ? 'Remove' : 'Upload' }}</span
      >
    </div>

    <!-- Password Icon Container -->
    <span
      *ngIf="showPasswordIcon"
      class="password-icon-container position-absolute"
      (click)="showPassword()"
    >
      <!-- Password Icon -->
      <img
        src="assets/icons/ic-visibility-{{ icon }}-{{ theme }}.svg"
        alt=""
        class="password-icon"
      />
    </span>

    <!-- Text Area Type Input Field -->
    <textarea
      (keyup)="inputKeyEventHandler($event)"
      *ngIf="type === 'textarea'"
      [value]="value"
      class="text-area"
      [placeholder]="placeholder"
      rows="4"
      (change)="inputValueHandler($event)"
      [maxLength]="maxLength"
      #textAreaRef
      [disabled]="disabled"
    ></textarea>

    <span class="info-message" *ngIf="infoMessage">{{infoMessage}}</span>
  </div>

  <!-- Counter Text -->
  <span
    class="counter position-absolute"
    *ngIf="showCounter"
    [ngClass]="{ 'label-enabled': showLabel, 'label-disabled': !showLabel }"
    >{{ inputCurrentLength }}/{{ maxLength }}</span
  >
</div>
