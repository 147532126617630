import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AlertBarComponent } from './alert-bar/alert-bar.component';
import { ButtonComponent } from './button/button.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CommonModule } from '@angular/common';
import { CountryDropdownComponent } from './country-dropdown/country-dropdown.component';
import { DropdownInputComponent } from './dropdown-input/dropdown-input.component';
import { FormsModule } from '@angular/forms';
import { InputComponent } from './input/input.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { LoaderComponent } from './loader/loader.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ModalComponent } from './modal/modal.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PasswordMaskDirective } from './directives/password.directive';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TableComponent } from './table/table.component';
import { ToggleComponent } from './toggle/toggle.component';

@NgModule({
  declarations: [
    ButtonComponent,
    TableComponent,
    SidebarComponent,
    ModalComponent,
    InputComponent,
    CountryDropdownComponent,
    ToggleComponent,
    PaginationComponent,
    CountryDropdownComponent,
    DropdownInputComponent,
    CheckboxComponent,
    PasswordMaskDirective,
    CalendarComponent,
    AlertBarComponent,
    LineChartComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [
    ButtonComponent,
    ModalComponent,
    InputComponent,
    CountryDropdownComponent,
    SidebarComponent,
    ToggleComponent,
    TableComponent,
    PaginationComponent,
    CountryDropdownComponent,
    CheckboxComponent,
    DropdownInputComponent,
    PasswordMaskDirective,
    CalendarComponent,
    AlertBarComponent,
    LineChartComponent,
    LoaderComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class SharedModule {}
